import React from "react"
import Helmet from "react-helmet"
import Header from "../components/layouts/header"
import css from "@emotion/css"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HeroBanner } from "../components/HeroBanner"
function Index({ data }) {
  const {
    contentfulProductPage: {
      pageTitle,
      metaDescription,
      heroBanner: { title, subtitle, buttonText, buttonLink, heroImage },
      subBanner,
      products,
    },
  } = data
  return (
    <>
    <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Ratio, fintech, finance, software, products, Ratio's products" />
    </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py ">
            <HeroBanner
              heroImage={heroImage}
              title={title}
              subtitle={subtitle}
              buttonText={buttonText}
              buttonLink={buttonLink}
            />
          </div>
        </div>
      </div>
      <section
        css={css`
          padding-top: 5rem;
          padding-bottom: 5rem;
        `}
      >
        <div
          className="container"
          css={css`
            h1,
            h2 {
              font-family: "futura-pt", sans-serif;
              font-weight: 600;

              margin-bottom: 2rem;
              color: var(--primary);
              column-span: all;
              @media (min-width: 992px) {
                max-width: 60%;
              }
            }
            h2 {
              font-size: 36px;
            }
            h3 {
              font-size: 28px;
            }
          `}
        >
          <div className="row">
            <div
              className="col-12 col-md-9 col-lg-6 offset-md-1"
              dangerouslySetInnerHTML={{ __html: subBanner.md.html }}
            />
          </div>
        </div>
      </section>
      <section id="producst">
        <div className="row no-gutters">
          {products.map((product, i) =>
            i % 3 === 0 ? (
              <div
                className="col-12"
                key={i}
                css={css`
                  & > div {
                    padding-bottom: 400px;
                    @media (min-width: 768px) {
                      padding-bottom: 700px;
                    }
                    border-bottom: 14px solid #fff;
                  }
                `}
              >
                <BackgroundImage
                  fadeIn="soft"
                  fluid={[
                    `linear-gradient(rgba(109, 126, 124,.5), rgba(109, 126, 124,.5))`,
                    product.BannerMain.fluid,
                  ]}
                  style={i !== 0 ? {
                    backgroundPosition: 'top',
                  } : null}
                >
                  <div
                    className="row position-absolute"
                    css={css`
                      width: calc(100% - 4rem);
                      bottom: 2rem;
                      left: 2rem;
                      @media (min-width: 768px) {
                        width: 100%;
                        bottom: 4rem;
                        left: 4rem;
                      }
                    `}
                  >
                    <div className="col-md-4 col-12">
                      <h2
                        css={css`
                          color: #fff;
                          font-family: "futura-pt", sans-serif;
                          font-size: 38px;
                          font-weight: 500;
                          line-height: 48px;
                          margin-bottom: 2rem;
                        `}
                      >
                        {product.title}
                      </h2>
                      <Link
                        css={css`
                          font-size: 18px;
                          @media (min-width: 768px) {
                            font-size: 22px;
                          }
                          font-weight: 600;
                          display: inline-block;
                          line-height: 27px;
                          color: #fff;
                          &:hover {
                            color: #fff;
                          }
                          padding: 0.75em 1.5em;
                          border: 4px solid #ffffff;
                          border-radius: 4px;
                          white-space: nowrap;
                        `}
                        to={`/products/${product.slug}/`}
                      >
                        Find Out More
                      </Link>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            ) : (
              <div
                className="col-md-6 col-12"
                key={i}
                css={css`
                  border-bottom: 14px solid #fff;
                  @media (min-width: 768px) {
                    ${i % 3 === 1
                      ? "border-right: 7px solid #fff"
                      : "border-left: 7px solid #fff"}
                  }
                  & > div {
                    padding-bottom: 400px;

                    @media (min-width: 768px) {
                      padding-bottom: 80%;
                    }
                  }
                `}
              >
                <BackgroundImage
                  fluid={[
                    `linear-gradient(rgba(109, 126, 124,.5), rgba(109, 126, 124,.5))`,
                    product.BannerMain.fluid,
                  ]}
                >
                  <div
                    className="row position-absolute"
                    css={css`
                      width: calc(100% - 4rem);
                      bottom: 2rem;
                      left: 2rem;
                      @media (min-width: 768px) {
                        width: 100%;
                        bottom: 4rem;
                        left: 4rem;
                      }
                    `}
                  >
                    <div className="col-md-4">
                      <h2
                        css={css`
                          color: #fff;
                          font-family: "futura-pt", sans-serif;
                          font-size: 38px;
                          font-weight: 500;
                          line-height: 48px;
                          margin-bottom: 2rem;
                        `}
                      >
                        {product.title}
                      </h2>
                      <Link
                        css={css`
                          font-size: 18px;
                          @media (min-width: 768px) {
                            font-size: 22px;
                          }
                          font-weight: 600;
                          display: inline-block;
                          line-height: 27px;
                          color: #fff;
                          &:hover {
                            color: #fff;
                          }
                          padding: 0.75em 1.5em;
                          border: 4px solid #ffffff;
                          border-radius: 4px;
                          white-space: nowrap;
                        `}
                        to={`/products/${product.slug}/`}
                      >
                        Find Out More
                      </Link>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            )
          )}
        </div>
      </section>
    </>
  )
}

export default Index

export const query = graphql`
  {
    contentfulProductPage {
      id
      pageTitle: title
      metaDescription
      heroBanner {
        title
        subtitle
        buttonText
        buttonLink
        heroImage {
          title
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
      subBanner {
        md: childMarkdownRemark {
          html
        }
      }
      products {
        title

        slug
        BannerMain {
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
